/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { RowDataWrapper } from "./styled";
import { Spin, Table } from "antd";
import { useState } from "react";
import { linkApi } from "../../common/ngok";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { showConfirm } from "../../helpers/modal-confirm";
import { Input, Button } from "antd";
const RowData = React.memo(() => {
  const { t } = useTranslation();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const citiesData = useSelector((state) => state.data.citiesData);

  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [numEntries, setNumEntries] = useState({});
  const [search, setSearch] = useState("");

  const getDataRow = async (page, search) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const myHeaders = new Headers({
      Authorization: "Token " + user?.token,
      "Content-Type": "application/x-www-form-urlencoded",
    });
    fetch(`${linkApi}/dm/data/raw?page=${page}&size=5&search=${search ? search : ""}`, {
      method: "GET",
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "successful") {
          setRowData(data?.data?.object_list);
          setNumEntries({
            total: data?.data?.num_entries,
            numPages: data?.data?.num_pages,
            page_number: data?.data?.page_number,
          });
        } else {
          showConfirm({
            title: t("common.accountNotAllowed"),
            hideCancel: true,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleDelete = async (id) => {
    showConfirm({
      title: t("rawData.confirmDelete"),
      onOk: async () => {
        const myHeaders = new Headers({
          Authorization: "Token " + user?.token,
          "Content-Type": "application/x-www-form-urlencoded",
        });
        try {
          const formData = new URLSearchParams();
          formData.append("record_ids", `[${id}]`);
          
          const response = await fetch(`${linkApi}/dm/data/raw`, {
            method: "DELETE",
            headers: myHeaders,
            body: formData
          });
          const data = await response.json();
          if (data?.status === "successful") {
            getDataRow(numEntries.page_number, search);
          }
        } catch (error) {
          console.error("Delete failed:", error);
        }
      },
    });
  };

  useEffect(() => {
    getDataRow(1, "");
  }, []);

  const arr = useMemo(() => {
    if (!rowData || rowData?.length === 0) {
      return null;
    }
    return (
      Object?.keys(rowData[0])?.map((val, idx) => ({
        title: `${val}`,
        dataIndex: `${val}`,
        key: `${val}`,
      })) || null
    );
  }, [rowData]);

  const columns = [
    {
      title: t("rawData.id"),
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: t("rawData.city"),
      dataIndex: "hosp_province",
      key: "hosp_province",
      render: (record) => {
        return (
          <div>
            {citiesData?.find((element) => element.code === record)?.name || ""}
          </div>
        );
      },
    },
    // {
    //   title: t("rawData.unitConducting"),
    //   dataIndex: "hosp_operator",
    //   key: "hosp_operator",
    // },
    {
      title: t("rawData.surveyCriteria"),
      dataIndex: "hosp_criteria_name",
      key: "hosp_criteria_name",
    },
    {
      title: t("rawData.hospitalSurveyed"),
      dataIndex: "hosp_hospname",
      key: "hosp_hospname",
    },
    {
      title: t("rawData.unitConducting"),
      dataIndex: "hosp_byt_syt",
      key: "hosp_byt_syt",
    },
    {
      title: t("rawData.motherName"),
      dataIndex: "minf_moname",
      key: "minf_moname",
    },
    {
      title: t("rawData.phoneNumber"),
      dataIndex: "minf_phone",
      key: "minf_phone",
    },
    // {
    //   title: "q00",
    //   dataIndex: "q00",
    //   key: "q00",
    // },
    {
      title: t("rawData.submissionTime"),
      dataIndex: "_submission_time",
      key: "_submission_time",
      render: (time) => {
        // Convert to Vietnam timezone (UTC+7)
        return new Date(time).toLocaleString('vi-VN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: 'Asia/Ho_Chi_Minh'
        });
      }
    },
    // {
    //   title: t("rawData.quarter"),
    //   dataIndex: "qrt",
    //   key: "qrt",
    // },
    // {
    //   title: t("rawData.yearBirth"),
    //   dataIndex: "byear",
    //   key: "byear",
    // },
    // {
    //   title: t("rawData.monthBirth"),
    //   dataIndex: "bmon",
    //   key: "bmon",
    // },
    // {
    //   title: "q01",
    //   dataIndex: "q_current_bf_q01",
    //   key: "q_current_bf_q01",
    // },
    // {
    //   title: "q01a",
    //   dataIndex: "q_current_bf_q01a",
    //   key: "q_current_bf_q01a",
    // },
    // {
    //   title: "q01b",
    //   dataIndex: "q_current_bf_q01b",
    //   key: "q_current_bf_q01b",
    // },
    // {
    //   title: "q02",
    //   dataIndex: "q_cbf_q02",
    //   key: "q_cbf_q02",
    // },
    // {
    //   title: "q02a",
    //   dataIndex: "q_cbf_q02a",
    //   key: "q_cbf_q02a",
    // },
    // {
    //   title: "q02b",
    //   dataIndex: "q_cbf_q02b",
    //   key: "q_cbf_q02b",
    // },
    // {
    //   title: "q03",
    //   dataIndex: "q_cbf_q03",
    //   key: "q_cbf_q03",
    // },
    // {
    //   title: "q03/1",
    //   dataIndex: "q_cbf_q03_1",
    //   key: "q_cbf_q03_1",
    // },
    // {
    //   title: "q03a",
    //   dataIndex: "q_cbf_q03a",
    //   key: "q_cbf_q03a",
    // },
    {
      title: t("rawData.action"),
      key: "action",
      render: (_, record) => (
        <button
          onClick={() => handleDelete(record._id)}
          style={{ color: "red", cursor: "pointer", border: "1px solid red", background: "transparent" }}
        >
          {t("rawData.delete")}
        </button>
      ),
    },
  ];

  return (
    <RowDataWrapper>
      <div>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 10, gap: 10 }}>
          <Input
            placeholder="Tìm kiếm theo KoboID, tên mẹ hoặc SĐT"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onPressEnter={() => getDataRow(1, search)}
            style={{ width: 350 }}
            allowClear
          />
          <Button type="primary" onClick={() => getDataRow(1, search)}>Tìm kiếm</Button>
        </div>
        {!arr && !isLoading && (
          <div style={{ textAlign: 'center', margin: '20px 0', fontSize: '16px', color: '#999' }}>
            {t("rawData.noRecordFound") || "No record found"}
          </div>
        )}
        {arr && (
          <Table
            className="table-row-data"
            columns={columns}
            dataSource={rowData}
            loading={isLoading}
            key={(recod) => recod.hosp_hospname}
            showSizeChanger={false}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              total: numEntries?.total,
              current: numEntries?.page_number,
              pageSizeOptions: ["5"],
              showTotal: (total, range) => (
                <div className="title-pagination">
                  <div className="position-absolute left-20">
                    {range[0]}-{range[1]} from {total}
                  </div>
                </div>
              ),
              locale: { items_per_page: "" },
              onChange: (pageChange) => {
                getDataRow(pageChange);
              },
            }}
          />
        )}
        {!arr && isLoading ? <Spin size="large" /> : <div />}
      </div>
    </RowDataWrapper>
  );
});

export default RowData;
